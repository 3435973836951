import ContactForm from '@/common/contact-form';
import Custom_hero from '@/common/custom_hero';
import { SEO } from '@/common/seo';

const Appointment: Page = () => (
    <div className="px-0 lg:px-[70px] 3xl:px-[150px] lg:mt-16 mt-0 mb-28 max-w-[1920px] mx-auto">
        <Custom_hero
            title="Reach Out To Us"
            label="Book Appointment"
            description="Get in touch and we'll get back to you as soon as we can.  We look forward to hearing from you"
            heroImage="Appointment.jpg"
        />
        <div className="px-[16px] lg:px-0">
            <div className="mt-28">
                <ContactForm />
            </div>
            <div className="mt-28">
                <div className="mb-8 text-center DH2MH3 text-primary">Zapata Dental Location</div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.749384667361!2d-112.18796338442748!3d33.48187505501741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b14e15e2ba8d5%3A0x91dd548a60c5394b!2sZapata%20Dental!5e0!3m2!1sen!2s!4v1663699959399!5m2!1sen!2s"
                    width="100%"
                    height="650"
                    style={{ borderRadius: 16 }}
                    aria-hidden="false"
                    title="zapata-dental"
                ></iframe>
            </div>
        </div>
    </div>
);

export default Appointment;

export const Head = () => (
    <SEO
        title="Reach Out to US - Book Dental Appointment - Zapata Dental"
        description="Get in touch and Get your booking confirmed within the next 24 hours by our staff member. We are always delighted to receive you at our dental office."
        pathname="/contact/"
    />
);
